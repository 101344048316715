var render = function render(){
  var _vm$cpuChartData$0$da, _vm$ramChartData$0$da, _vm$batteryLineChartD;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.busy ? _c('v-row', _vm._l(4, function (i) {
    return _c('v-col', {
      key: i,
      attrs: {
        "cols": "12"
      }
    }, [_c('v-skeleton-loader', {
      attrs: {
        "type": "card, list"
      }
    })], 1);
  }), 1) : _c('v-row', {
    attrs: {
      "justify": "start"
    }
  }, [_c('v-col', {
    staticClass: "chart-row",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "mx-2 chart-label mt-3"
  }, [_c('v-icon', [_vm._v("memory")]), _c('div', {
    staticClass: "subListFont mx-2"
  }, [_vm._v("CPU")])], 1), _vm.cpuChartData && _vm.cpuChartData[0] && ((_vm$cpuChartData$0$da = _vm.cpuChartData[0].data) === null || _vm$cpuChartData$0$da === void 0 ? void 0 : _vm$cpuChartData$0$da.length) > 0 ? _c('apexchart', {
    staticClass: "chart-wrapper",
    attrs: {
      "height": 100,
      "options": _vm.apexOptions,
      "series": _vm.cpuChartData,
      "type": "line"
    }
  }) : _c('no-data', {
    attrs: {
      "first-text": "No data"
    }
  })], 1), _c('v-col', {
    staticClass: "compact chart-row",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "mx-2 chart-label mt-3"
  }, [_c('v-icon', [_vm._v("memory")]), _c('div', {
    staticClass: "subListFont mx-2"
  }, [_vm._v("Storage")])], 1), _vm.storageChartData && _vm.storageChartData.length > 0 ? _c('apexchart', {
    staticClass: "chart-wrapper",
    attrs: {
      "height": 100,
      "options": _vm.storageChartOptions,
      "series": _vm.storageChartData,
      "type": "line"
    }
  }) : _c('no-data', {
    attrs: {
      "first-text": "No data"
    }
  })], 1), _c('v-col', {
    staticClass: "compact chart-row",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "mx-2 chart-label mt-3"
  }, [_c('v-icon', [_vm._v("memory")]), _c('div', {
    staticClass: "subListFont mx-2"
  }, [_vm._v("RAM")])], 1), _vm.ramChartData && _vm.ramChartData[0] && ((_vm$ramChartData$0$da = _vm.ramChartData[0].data) === null || _vm$ramChartData$0$da === void 0 ? void 0 : _vm$ramChartData$0$da.length) > 0 ? _c('apexchart', {
    staticClass: "chart-wrapper",
    attrs: {
      "height": 100,
      "options": _vm.ramChartOptions,
      "series": _vm.ramChartData,
      "type": "line"
    }
  }) : _c('no-data', {
    attrs: {
      "first-text": "No data"
    }
  })], 1), _c('v-col', {
    staticClass: "compact chart-row",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "mx-2 chart-label mt-3"
  }, [_c('v-icon', [_vm._v("memory")]), _c('div', {
    staticClass: "subListFont mx-2"
  }, [_vm._v("Battery Line chart")])], 1), _vm.batteryLineChartData && _vm.batteryLineChartData[0] && ((_vm$batteryLineChartD = _vm.batteryLineChartData[0].data) === null || _vm$batteryLineChartD === void 0 ? void 0 : _vm$batteryLineChartD.length) > 0 ? _c('apexchart', {
    staticClass: "chart-wrapper",
    attrs: {
      "height": 100,
      "options": _vm.batteryLineChartOptions,
      "series": _vm.batteryLineChartData,
      "type": "line"
    }
  }) : _c('no-data', {
    attrs: {
      "first-text": "No data"
    }
  })], 1), _c('v-col', {
    staticClass: "compact chart-row",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "mx-2 chart-label mt-3"
  }, [_c('v-icon', [_vm._v("memory")]), _c('div', {
    staticClass: "subListFont"
  }, [_vm._v("Network Demo")])], 1), _vm.networkChartData && _vm.networkChartData.length > 0 ? _c('apexchart', {
    staticClass: "chart-wrapper",
    attrs: {
      "options": _vm.networkOptions,
      "series": _vm.networkChartData,
      "height": "75",
      "type": "scatter",
      "width": "95%"
    }
  }) : _c('no-data', {
    attrs: {
      "first-text": "No data"
    }
  })], 1), _c('v-col', {
    staticClass: "compact chart-row",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "mx-2 chart-label mt-3"
  }, [_c('v-icon', [_vm._v("memory")]), _c('div', {
    staticClass: "subListFont"
  }, [_vm._v("Events Demo")])], 1), _vm.eventsChartData && _vm.eventsChartData.length > 0 ? _c('apexchart', {
    staticClass: "chart-wrapper",
    attrs: {
      "options": _vm.eventOptions,
      "series": _vm.eventsChartData,
      "height": "75",
      "type": "scatter",
      "width": "95%"
    }
  }) : _c('no-data', {
    attrs: {
      "first-text": "No data"
    }
  })], 1), _c('v-col', {
    staticClass: "compact chart-row",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "mx-2 chart-label mt-3"
  }, [_c('v-icon', [_vm._v("memory")]), _c('div', {
    staticClass: "subListFont"
  }, [_vm._v("Events Demo")])], 1), _vm.errorChartData && _vm.errorChartData.length > 0 ? _c('apexchart', {
    staticClass: "chart-wrapper",
    attrs: {
      "options": _vm.errorOptions,
      "series": _vm.errorChartData,
      "height": "75",
      "type": "scatter",
      "width": "95%"
    }
  }) : _c('no-data', {
    attrs: {
      "first-text": "No data"
    }
  })], 1), _c('v-col', {
    staticClass: "compact chart-row",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "mx-2 chart-label mt-3"
  }, [_c('v-icon', [_vm._v("memory")]), _c('div', {
    staticClass: "subListFont"
  }, [_vm._v("Battery Stats")])], 1), _vm.batterySeries && _vm.batterySeries.length > 0 ? _c('apexchart', {
    staticClass: "chart-wrapper",
    attrs: {
      "options": _vm.batteryChartOptions,
      "series": _vm.batterySeries,
      "height": "100",
      "type": "rangeBar"
    }
  }) : _c('no-data', {
    attrs: {
      "first-text": "No data"
    }
  })], 1), _c('v-col', {
    staticClass: "compact chart-row",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "mx-2 chart-label mt-3"
  }, [_c('v-icon', [_vm._v("memory")]), _c('div', {
    staticClass: "subListFont"
  }, [_vm._v("Connectivity")])], 1), _vm.networkSeries && _vm.networkSeries.length > 0 ? _c('apexchart', {
    staticClass: "chart-wrapper",
    attrs: {
      "options": _vm.connectivityOptions,
      "series": _vm.networkSeries,
      "height": "100",
      "type": "rangeBar",
      "width": "100%"
    }
  }) : _c('no-data', {
    attrs: {
      "first-text": "No data"
    }
  })], 1), _c('v-col', {
    staticClass: "compact chart-row",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "mx-2 chart-label mt-3"
  }, [_c('v-icon', [_vm._v("memory")]), _c('div', {
    staticClass: "subListFont"
  }, [_vm._v("Device Orientation")])], 1), _vm.orientationSeries && _vm.orientationSeries.length > 0 ? _c('apexchart', {
    staticClass: "chart-wrapper",
    attrs: {
      "options": _vm.orientationOptions,
      "series": _vm.orientationSeries,
      "height": "100",
      "type": "rangeBar"
    }
  }) : _c('no-data', {
    attrs: {
      "first-text": "No data"
    }
  })], 1), _c('v-col', {
    staticClass: "compact chart-row",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "mx-2 chart-label mt-3"
  }, [_c('v-icon', [_vm._v("memory")]), _c('div', {
    staticClass: "subListFont"
  }, [_vm._v("Interface Orientation Series")])], 1), _vm.interfaceOrientationSeries && _vm.interfaceOrientationSeries.length > 0 ? _c('apexchart', {
    staticClass: "chart-wrapper",
    attrs: {
      "options": _vm.interfaceOrientationOptions,
      "series": _vm.interfaceOrientationSeries,
      "height": "100",
      "type": "rangeBar"
    }
  }) : _c('no-data', {
    attrs: {
      "first-text": "No data"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }