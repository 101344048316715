<template>
  <div>
    <v-row v-if="busy">
      <v-col v-for="i in 4" :key="i" cols="12">
        <v-skeleton-loader type="card, list"></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row v-else justify="start">
      <!-- CPU -->
      <v-col class="chart-row" cols="12">
        <div class="mx-2 chart-label mt-3">
          <v-icon>memory</v-icon>
          <div class="subListFont mx-2">CPU</div>
        </div>

        <apexchart
          v-if="
            cpuChartData && cpuChartData[0] && cpuChartData[0].data?.length > 0
          "
          :height="100"
          :options="apexOptions"
          :series="cpuChartData"
          class="chart-wrapper"
          type="line"
        />
        <no-data v-else first-text="No data"></no-data>
      </v-col>

      <!-- Storage -->
      <v-col class="compact chart-row" cols="12">
        <div class="mx-2 chart-label mt-3">
          <v-icon>memory</v-icon>
          <div class="subListFont mx-2">Storage</div>
        </div>

        <apexchart
          v-if="storageChartData && storageChartData.length > 0"
          :height="100"
          :options="storageChartOptions"
          :series="storageChartData"
          class="chart-wrapper"
          type="line"
        />

        <no-data v-else first-text="No data"></no-data>
      </v-col>

      <!-- RAM -->
      <v-col class="compact chart-row" cols="12">
        <div class="mx-2 chart-label mt-3">
          <v-icon>memory</v-icon>
          <div class="subListFont mx-2">RAM</div>
        </div>

        <apexchart
          v-if="
            ramChartData && ramChartData[0] && ramChartData[0].data?.length > 0
          "
          :height="100"
          :options="ramChartOptions"
          :series="ramChartData"
          class="chart-wrapper"
          type="line"
        />
        <no-data v-else first-text="No data"></no-data>
      </v-col>

      <!-- Battery Line chart -->
      <v-col class="compact chart-row" cols="12">
        <div class="mx-2 chart-label mt-3">
          <v-icon>memory</v-icon>
          <div class="subListFont mx-2">Battery Line chart</div>
        </div>

        <apexchart
          v-if="
            batteryLineChartData &&
            batteryLineChartData[0] &&
            batteryLineChartData[0].data?.length > 0
          "
          :height="100"
          :options="batteryLineChartOptions"
          :series="batteryLineChartData"
          class="chart-wrapper"
          type="line"
        />

        <no-data v-else first-text="No data"></no-data>
      </v-col>

      <!-- Network -->
      <v-col class="compact chart-row" cols="12">
        <div class="mx-2 chart-label mt-3">
          <v-icon>memory</v-icon>
          <div class="subListFont">Network Demo</div>
        </div>

        <apexchart
          v-if="networkChartData && networkChartData.length > 0"
          :options="networkOptions"
          :series="networkChartData"
          class="chart-wrapper"
          height="75"
          type="scatter"
          width="95%"
        />

        <no-data v-else first-text="No data"></no-data>
      </v-col>

      <!-- Events -->
      <v-col class="compact chart-row" cols="12">
        <div class="mx-2 chart-label mt-3">
          <v-icon>memory</v-icon>
          <div class="subListFont">Events Demo</div>
        </div>

        <apexchart
          v-if="eventsChartData && eventsChartData.length > 0"
          :options="eventOptions"
          :series="eventsChartData"
          class="chart-wrapper"
          height="75"
          type="scatter"
          width="95%"
        />

        <no-data v-else first-text="No data"></no-data>
      </v-col>

      <!-- Error -->
      <v-col class="compact chart-row" cols="12">
        <div class="mx-2 chart-label mt-3">
          <v-icon>memory</v-icon>
          <div class="subListFont">Events Demo</div>
        </div>

        <apexchart
          v-if="errorChartData && errorChartData.length > 0"
          :options="errorOptions"
          :series="errorChartData"
          class="chart-wrapper"
          height="75"
          type="scatter"
          width="95%"
        />

        <no-data v-else first-text="No data"></no-data>
      </v-col>

      <!-- Battery -->
      <v-col class="compact chart-row" cols="12">
        <div class="mx-2 chart-label mt-3">
          <v-icon>memory</v-icon>
          <div class="subListFont">Battery Stats</div>
        </div>

        <apexchart
          v-if="batterySeries && batterySeries.length > 0"
          :options="batteryChartOptions"
          :series="batterySeries"
          class="chart-wrapper"
          height="100"
          type="rangeBar"
        />

        <no-data v-else first-text="No data"></no-data>
      </v-col>

      <!-- Connectivity -->
      <v-col class="compact chart-row" cols="12">
        <div class="mx-2 chart-label mt-3">
          <v-icon>memory</v-icon>
          <div class="subListFont">Connectivity</div>
        </div>

        <apexchart
          v-if="networkSeries && networkSeries.length > 0"
          :options="connectivityOptions"
          :series="networkSeries"
          class="chart-wrapper"
          height="100"
          type="rangeBar"
          width="100%"
        />

        <no-data v-else first-text="No data"></no-data>
      </v-col>

      <!-- Device Orientation -->
      <v-col class="compact chart-row" cols="12">
        <div class="mx-2 chart-label mt-3">
          <v-icon>memory</v-icon>
          <div class="subListFont">Device Orientation</div>
        </div>
        <apexchart
          v-if="orientationSeries && orientationSeries.length > 0"
          :options="orientationOptions"
          :series="orientationSeries"
          class="chart-wrapper"
          height="100"
          type="rangeBar"
        />

        <no-data v-else first-text="No data"></no-data>
      </v-col>

      <!-- Interface Orientation -->
      <v-col class="compact chart-row" cols="12">
        <div class="mx-2 chart-label mt-3">
          <v-icon>memory</v-icon>
          <div class="subListFont">Interface Orientation Series</div>
        </div>

        <apexchart
          v-if="
            interfaceOrientationSeries && interfaceOrientationSeries.length > 0
          "
          :options="interfaceOrientationOptions"
          :series="interfaceOrientationSeries"
          class="chart-wrapper"
          height="100"
          type="rangeBar"
        />

        <no-data v-else first-text="No data"></no-data>
      </v-col>

      <v-col cols="12"></v-col>
    </v-row>
  </div>
</template>

<script>
import { GET_SESSION_PERFORMANCE } from "@/store/apps/sessions.module";
import { formatBytes } from "@/core/services/helper.service";
import { mapGetters } from "vuex";
import dayjs from "dayjs";

export default {
  data() {
    return {
      dayjs,
      formatBytes,
      apexOptions: {
        stacked: true,
        stroke: {
          width: 1.25,
        },
        chart: {
          width: "100%",
          height: "50px",
          id: "cpu",
          group: "session",
          toolbar: {
            show: false,
          },

          type: "line",
          zoom: {
            enabled: true,
          },
        },
        xaxis: {
          categories: [],
          show: false,
          labels: {
            show: false,
          },
          gridLines: {
            show: false,
          },
          tooltip: {
            enabled: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          lines: {
            show: false,
          },
        },
        yaxis: {
          show: false,
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          lines: {
            show: false,
          },
        },
        tooltip: {
          custom: function ({ seriesIndex, w, dataPointIndex }) {
            // let labels =
            let labels = dayjs(w.globals.categoryLabels[dataPointIndex]).format(
              "hh:mm:ss"
            );
            let data =
              w.globals.initialSeries[seriesIndex].data[dataPointIndex];
            // const start = dayjs(data.x * 1000).format= ('hh:mm:ss a')
            return /*html*/ `<v-card class="pa-2">
                  <div><strong>${data}%</strong> ${labels} </div>
                 </v-card>`;
          },
        },
        grid: {
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
      },
      ramChartOptions: {
        stroke: {
          width: 1.25,
        },
        chart: {
          width: "100%",
          height: "50px",
          id: "ram-chart",
          group: "session",
          toolbar: {
            show: false,
          },
          type: "line",
          zoom: {
            enabled: true,
          },
        },
        xaxis: {
          categories: [],
          show: false,
          labels: {
            show: false,
          },
          tooltip: {
            enabled: false,
          },
          gridLines: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          show: false,
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        tooltip: {
          custom: function ({ seriesIndex, w, dataPointIndex }) {
            // let labels =
            let labels = dayjs(w.globals.categoryLabels[dataPointIndex]).format(
              "hh:mm:ss"
            );
            let data =
              w.globals.initialSeries[seriesIndex].data[dataPointIndex];
            // const start = dayjs(data.x * 1000).format= ('hh:mm:ss a')
            return /*html*/ `<v-card class="pa-2">
                  <div><strong>${data}%</strong> ${labels} </div>
                 </v-card>`;
          },
        },
        grid: {
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
      },
      storageChartOptions: {
        stroke: {
          width: 1.25,
        },
        chart: {
          width: "100%",
          height: "50px",
          id: "storage-chart",
          group: "session",
          toolbar: {
            show: false,
          },
          type: "line",
          zoom: {
            enabled: true,
          },
        },
        xaxis: {
          categories: [],
          show: false,
          labels: {
            show: false,
          },
          tooltip: {
            enabled: false,
          },
          gridLines: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          show: false,
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        tooltip: {
          custom: function ({ seriesIndex, w, dataPointIndex }) {
            // let labels =
            let labels = dayjs(w.globals.categoryLabels[dataPointIndex]).format(
              "hh:mm:ss"
            );
            let data =
              w.globals.initialSeries[seriesIndex].data[dataPointIndex];
            // const start = dayjs(data.x * 1000).format= ('hh:mm:ss a')
            return /*html*/ `<v-card class="pa-2">
                  <div><strong>${data}MB</strong> ${labels} </div>
                 </v-card>`;
          },
        },
        grid: {
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
      },
      batteryLineChartOptions: {
        chart: {
          id: "lineBattary",

          toolbar: {
            show: false,
          },
          type: "line",
          zoom: {
            enabled: true,
          },
        },
        xaxis: {
          categories: [],
          show: false,
          labels: {
            show: false,
          },
          gridLines: {
            show: false,
          },
          tooltip: {
            enabled: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          show: false,
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        tooltip: {
          custom: function ({ seriesIndex, w, dataPointIndex }) {
            // let labels =
            let labels = dayjs(w.globals.categoryLabels[dataPointIndex]).format(
              "hh:mm:ss"
            );
            let data =
              w.globals.initialSeries[seriesIndex].data[dataPointIndex];
            // const start = dayjs(data.x * 1000).format= ('hh:mm:ss a')
            return /*html*/ `<v-card class="pa-2">
                  <div><strong>${data}%</strong> ${labels} </div>
                 </v-card>`;
          },
        },
        annotations: {
          points: [],
        },
        grid: {
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
      },
      batteryLineChartData: [
        {
          name: "Battery",
          data: [],
        },
      ],
      cpuChartData: [
        {
          name: "CPU",
          data: [],
        },
      ],
      ramChartData: [
        {
          name: "RAM",
          data: [],
        },
      ],
      storageChartData: [
        {
          name: "storage",
          data: [],
        },
      ],
      busy: false,
      connectivityOptions: {
        chart: {
          type: "rangeBar",
          id: "rangeChart",

          toolbar: {
            show: false,
          },
          zoom: {
            enabled: true,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: "50%",
            rangeBarGroupRows: true,
          },
        },
        colors: [
          "#008FFB",
          "#00E396",
          "#FEB019",
          "#FF4560",
          "#775DD0",
          "#3F51B5",
          "#546E7A",
          "#D4526E",
          "#8D5B4C",
          "#F86624",
          "#D7263D",
          "#1B998B",
          "#2E294E",
          "#F46036",
          "#E2C044",
        ],
        fill: {
          type: "solid",
        },
        dataLabels: {
          enabled: true,
          formatter: function (val, { seriesIndex, w, dataPointIndex }) {
            let data =
              w.globals.initialSeries[seriesIndex].data[dataPointIndex];
            return data.info;
          },
          style: {
            colors: ["#f3f4f5", "#fff"],
          },
        },
        xaxis: {
          type: "datetime",
          startTime: "",
          labels: {
            show: false,
          },
        },
        yaxis: {
          labels: {
            show: false,
          },
        },
        legend: {
          position: "right",
          show: false,
        },
        tooltip: {
          custom: function ({ seriesIndex, y1, y2, w, dataPointIndex }) {
            let data =
              w.globals.initialSeries[seriesIndex].data[dataPointIndex];
            const start = dayjs(y1 * 1000).format("hh:mm:ss a");
            const end = dayjs(y2 * 1000).format("hh:mm:ss a");
            return `<v-card class="pa-2">
                 <v-card-title>
                    ${data.info}
                  </v-card-title>
                  <v-card-text>
                    <ul>
                      <li>${start + " " + data.timezone + " - " + end} ${
              data.timezone
            }</li>
                    </ul>
                  </v-card-text>
               </v-card>`;
          },
        },
        grid: {
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
      },
      networkSeries: [],
      batterySeries: [],
      batteryChartOptions: {
        chart: {
          type: "rangeBar",
          id: "rangeChart",
          width: "100%",
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: true,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: "50%",
            rangeBarGroupRows: true,
          },
        },
        colors: [
          "#008FFB",
          "#00E396",
          "#FEB019",
          "#FF4560",
          "#775DD0",
          "#3F51B5",
          "#546E7A",
          "#D4526E",
          "#8D5B4C",
          "#F86624",
          "#D7263D",
          "#1B998B",
          "#2E294E",
          "#F46036",
          "#E2C044",
        ],
        fill: {
          type: "solid",
        },
        dataLabels: {
          enabled: true,
          formatter: function (val, { seriesIndex, w, dataPointIndex }) {
            let data =
              w.globals.initialSeries[seriesIndex].data[dataPointIndex];
            return data.info;
          },
          style: {
            colors: ["#f3f4f5", "#fff"],
          },
        },
        xaxis: {
          type: "datetime",
          labels: {
            show: false,
          },
        },
        yaxis: {
          labels: {
            show: false,
          },
        },
        legend: {
          position: "right",
          show: false,
        },
        tooltip: {
          custom: function ({ seriesIndex, y1, y2, w, dataPointIndex }) {
            let data =
              w.globals.initialSeries[seriesIndex].data[dataPointIndex];
            const start = dayjs(y1 * 1000).format("hh:mm:ss a");
            const end = dayjs(y2 * 1000).format("hh:mm:ss a");
            return `<v-card class="pa-2">
                 <v-card-title>
                    ${data.info}
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text>
                    <ul>
                      <li >Battery: ${data.percentage || ""} %</li>
                      <li >Low power: ${data.power_mode}</li>
                      <li>Time: ${start + " - " + end} ${data.timezone}</li>
                    </ul>

                  </v-card-text>
               </v-card>`;
          },
        },
        grid: {
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
      },
      networkInfos: {
        wifi: "Wi-Fi",
        noInternet: "No Internet",
        "4g": "4G",
        "3g": "3G",
        "2g": "2G",
        unknown: "Unknown",
        "5g": "5G",
      },
      orientationOptions: {
        chart: {
          type: "rangeBar",
          id: "orientationChart",

          toolbar: {
            show: false,
          },
          zoom: {
            enabled: true,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: "50%",
            rangeBarGroupRows: true,
          },
        },
        colors: [
          "#008FFB",
          "#00E396",
          "#FEB019",
          "#FF4560",
          "#775DD0",
          "#3F51B5",
          "#546E7A",
          "#D4526E",
          "#8D5B4C",
          "#F86624",
          "#D7263D",
          "#1B998B",
          "#2E294E",
          "#F46036",
          "#E2C044",
        ],
        fill: {
          type: "solid",
        },
        dataLabels: {
          enabled: true,
          formatter: function (val, { seriesIndex, w, dataPointIndex }) {
            let data =
              w.globals.initialSeries[seriesIndex].data[dataPointIndex];
            return data.info;
          },
          style: {
            colors: ["#f3f4f5", "#fff"],
          },
        },
        xaxis: {
          type: "datetime",
          startTime: "",
          labels: {
            show: false,
          },
        },
        yaxis: {
          labels: {
            show: false,
          },
        },
        legend: {
          position: "right",
          show: false,
        },
        tooltip: {
          custom: function ({ seriesIndex, y1, y2, w, dataPointIndex }) {
            let data =
              w.globals.initialSeries[seriesIndex].data[dataPointIndex];
            const start = dayjs(y1 * 1000).format("hh:mm:ss a");
            const end = dayjs(y2 * 1000).format("hh:mm:ss a");
            return `<v-card class="pa-2">
                 <v-card-title>
                    ${data.info}
                  </v-card-title>
                  <v-card-text>
                    <ul>
                      <li>${start + " " + data.timezone + " - " + end} ${
              data.timezone
            }</li>
                    </ul>
                  </v-card-text>
               </v-card>`;
          },
        },
        grid: {
          yaxis: {
            lines: {
              show: false,
            },
          },
          xaxis: {
            lines: {
              show: false,
            },
          },
        },
      },
      orientationTypes: {
        Portrait: "Portrait",
        Landscape: "Landscape",
      },
      orientationSeries: [],
      interfaceOrientationSeries: [],
      interfaceOrientationOptions: {
        chart: {
          type: "rangeBar",
          id: "interfaceOrientation",

          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: "50%",
            rangeBarGroupRows: true,
          },
        },
        colors: [
          "#008FFB",
          "#00E396",
          "#FEB019",
          "#FF4560",
          "#775DD0",
          "#3F51B5",
          "#546E7A",
          "#D4526E",
          "#8D5B4C",
          "#F86624",
          "#D7263D",
          "#1B998B",
          "#2E294E",
          "#F46036",
          "#E2C044",
        ],
        fill: {
          type: "solid",
        },
        dataLabels: {
          enabled: true,
          formatter: function (val, { seriesIndex, w, dataPointIndex }) {
            let data =
              w.globals.initialSeries[seriesIndex].data[dataPointIndex];
            return data.info;
          },
          style: {
            colors: ["#f3f4f5", "#fff"],
          },
        },
        xaxis: {
          type: "datetime",
          startTime: "",
          labels: {
            show: false,
          },
        },
        yaxis: {
          labels: {
            show: false,
          },
        },
        legend: {
          position: "right",
          show: false,
        },
        tooltip: {
          custom: function ({ seriesIndex, y1, y2, w, dataPointIndex }) {
            let data =
              w.globals.initialSeries[seriesIndex].data[dataPointIndex];
            const start = dayjs(y1 * 1000).format("hh:mm:ss a");
            const end = dayjs(y2 * 1000).format("hh:mm:ss a");
            return `<v-card class="pa-2">
                 <v-card-title>
                    ${data.info}
                  </v-card-title>
                  <v-card-text>
                    <ul>
                      <li>${start + " " + data.timezone + " - " + end} ${
              data.timezone
            }</li>
                    </ul>
                  </v-card-text>
               </v-card>`;
          },
        },
      },
      errorOptions: {
        chart: {
          id: "error-chart",

          toolbar: {
            show: false,
          },
          type: "scatter",
          zoom: {
            enabled: true,
          },
        },
        legend: {
          position: "bottom",
          show: false,
        },
        colors: ["#D7263D"],
        tooltip: {
          custom: function ({ seriesIndex, w, dataPointIndex }) {
            let data =
              w.globals.initialSeries[seriesIndex].data[dataPointIndex];
            let eventClass = {
              error: "error",
              event: "primary",
            };
            const start = dayjs(data.x * 1000).format("hh:mm:ss a");
            return `<v-card class="pa-2">
                  <ul>
                  <li>${data.info}: ${start} ${data.timezone}</li>
                  <li>Type: <span class="${
                    eventClass[data.type]
                  }--text text-capitalize">${data.type}</span></li>
                  </ul>
                 </v-card>`;
          },
        },
        xaxis: {
          title: "Events",
          categories: [],
          // type: 'category',
          show: false,
          labels: {
            show: false,
          },
          gridLines: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          tooltip: {
            enabled: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          show: false,
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        grid: {
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
      },
      errorChartData: [
        {
          name: "ActivityNotFoundException",
          data: [
            {
              x: 1672757916,
              y: 1672757917,
              info: "ActivityNotFoundException",
              type: "error",
              timezone: "GMT",
            },
          ],
        },
        {
          name: "Error converting byte to dex",
          data: [
            {
              x: 1672757919,
              y: 1672757920,
              info: "Error converting byte to dex",
              type: "error",
              timezone: "GMT",
            },
          ],
        },
        {
          name: "OutofMemoryError",
          data: [
            {
              x: 1672757921,
              y: 1672757922,
              info: "App crash",
              type: "error",
              timezone: "GMT",
            },
          ],
        },
      ],
      eventOptions: {
        chart: {
          id: "event-chart",

          toolbar: {
            show: false,
          },
          type: "scatter",
          zoom: {
            enabled: true,
          },
        },
        legend: {
          position: "bottom",
          show: false,
        },
        colors: ["#008FFB"],
        tooltip: {
          custom: function ({ seriesIndex, w, dataPointIndex }) {
            let data =
              w.globals.initialSeries[seriesIndex].data[dataPointIndex];
            let eventClass = {
              error: "error",
              event: "primary",
            };
            const start = dayjs(data.x * 1000).format("hh:mm:ss a");
            return `<v-card class="pa-2">
                  <ul>
                  <li>${data.info}: ${start} ${data.timezone}</li>
                  <li>Type: <span class="${
                    eventClass[data.type]
                  }--text text-capitalize">${data.type}</span></li>
                  </ul>
                 </v-card>`;
          },
        },
        xaxis: {
          title: "Events",
          categories: [],
          // type: 'category',
          show: false,
          labels: {
            show: false,
          },
          gridLines: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          tooltip: {
            enabled: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          show: false,
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        grid: {
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
      },
      eventsChartData: [
        {
          name: "Application installed",
          data: [
            {
              x: 1672757916,
              y: 1672757917,
              info: "Application installed",
              type: "event",
              timezone: "GMT",
            },
          ],
        },
        {
          name: "Application opened",
          data: [
            {
              x: 1672757920,
              y: 1672757921,
              info: "Application opened",
              type: "event",
              timezone: "GMT",
            },
          ],
        },
        {
          name: "Application updated",
          data: [
            {
              x: 1672757922,
              y: 1672757923,
              info: "Application updated",
              type: "event",
              timezone: "GMT",
            },
          ],
        },
        {
          name: "Viewed signin screen",
          data: [
            {
              x: 1672757923,
              y: 1672757924,
              info: "Viewed signin screen",
              type: "event",
              timezone: "GMT",
            },
          ],
        },
        {
          name: "Sign In",
          data: [
            {
              x: 1672757924,
              y: 1672757925,
              info: "Sign In",
              type: "event",
              timezone: "GMT",
            },
          ],
        },
        {
          name: "Added item to cart",
          data: [
            {
              x: 1672757925,
              y: 1672757926,
              info: "Added item to cart",
              type: "event",
              timezone: "GMT",
            },
          ],
        },
        {
          name: "Opened category page",
          data: [
            {
              x: 1672757926,
              y: 1672757927,
              info: "Opened category page",
              type: "event",
              timezone: "GMT",
            },
          ],
        },
        {
          name: "Removed item from cart",
          data: [
            {
              x: 1672757929,
              y: 1672757930,
              info: "Removed item from cart",
              type: "event",
              timezone: "GMT",
            },
          ],
        },
        {
          name: "Redirected from push notification",
          data: [
            {
              x: 1672757931,
              y: 1672757932,
              info: "Redirected from push notification",
              type: "event",
              timezone: "GMT",
            },
          ],
        },
        {
          name: "Viewed order list",
          data: [
            {
              x: 1672757932,
              y: 1672757933,
              info: "Viewed order list",
              type: "event",
              timezone: "GMT",
            },
          ],
        },
        {
          name: "Cancel order",
          data: [
            {
              x: 1672757933,
              y: 1672757934,
              info: "Cancel order",
              type: "event",
              timezone: "GMT",
            },
          ],
        },
        {
          name: "Updated Shipping Address",
          data: [
            {
              x: 1672757933,
              y: 1672757934,
              info: "Updated Shipping Address",
              type: "event",
              timezone: "GMT",
            },
          ],
        },
        {
          name: "Process Checkout",
          data: [
            {
              x: 1672757934,
              y: 1672757935,
              info: "Process Checkout",
              type: "event",
              timezone: "GMT",
            },
          ],
        },
      ],
      networkOptions: {
        chart: {
          id: "network-chart",

          toolbar: {
            show: false,
          },
          type: "scatter",
          zoom: {
            enabled: true,
          },
        },
        legend: {
          position: "bottom",
          show: false,
        },
        colors: ["#008FFB"],
        tooltip: {
          custom: function ({ seriesIndex, w, dataPointIndex }) {
            let data =
              w.globals.initialSeries[seriesIndex].data[dataPointIndex];
            let eventClass = {
              error: "error",
              event: "primary",
            };
            const start = dayjs(data.x * 1000).format("hh:mm:ss a");
            return `<v-card class="pa-2">
                  <ul>
                  <li>${data.info}: ${start} ${data.timezone}</li>
                  <li>Type: <span class="${
                    eventClass[data.type]
                  }--text text-capitalize">${data.type}</span></li>
                  </ul>
                 </v-card>`;
          },
        },
        xaxis: {
          title: "Events",
          categories: [],
          // type: 'category',
          show: false,
          labels: {
            show: false,
          },
          gridLines: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          tooltip: {
            enabled: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          show: false,
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        grid: {
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
      },
      networkChartData: [
        {
          name: "Application installed",
          data: [
            {
              x: 1672757916,
              y: 1672757917,
              info: "Application installed",
              type: "event",
              timezone: "GMT",
            },
          ],
        },
        {
          name: "Application opened",
          data: [
            {
              x: 1672757920,
              y: 1672757921,
              info: "Application opened",
              type: "event",
              timezone: "GMT",
            },
          ],
        },
        {
          name: "Application updated",
          data: [
            {
              x: 1672757922,
              y: 1672757923,
              info: "Application updated",
              type: "event",
              timezone: "GMT",
            },
          ],
        },
        {
          name: "Viewed signin screen",
          data: [
            {
              x: 1672757923,
              y: 1672757924,
              info: "Viewed signin screen",
              type: "event",
              timezone: "GMT",
            },
          ],
        },
        {
          name: "Sign In",
          data: [
            {
              x: 1672757924,
              y: 1672757925,
              info: "Sign In",
              type: "event",
              timezone: "GMT",
            },
          ],
        },
        {
          name: "Added item to cart",
          data: [
            {
              x: 1672757925,
              y: 1672757926,
              info: "Added item to cart",
              type: "event",
              timezone: "GMT",
            },
          ],
        },
        {
          name: "Opened category page",
          data: [
            {
              x: 1672757926,
              y: 1672757927,
              info: "Opened category page",
              type: "event",
              timezone: "GMT",
            },
          ],
        },
        {
          name: "Removed item from cart",
          data: [
            {
              x: 1672757929,
              y: 1672757930,
              info: "Removed item from cart",
              type: "event",
              timezone: "GMT",
            },
          ],
        },
        {
          name: "Redirected from push notification",
          data: [
            {
              x: 1672757931,
              y: 1672757932,
              info: "Redirected from push notification",
              type: "event",
              timezone: "GMT",
            },
          ],
        },
        {
          name: "Viewed order list",
          data: [
            {
              x: 1672757932,
              y: 1672757933,
              info: "Viewed order list",
              type: "event",
              timezone: "GMT",
            },
          ],
        },
        {
          name: "Cancel order",
          data: [
            {
              x: 1672757933,
              y: 1672757934,
              info: "Cancel order",
              type: "event",
              timezone: "GMT",
            },
          ],
        },
        {
          name: "Updated Shipping Address",
          data: [
            {
              x: 1672757933,
              y: 1672757934,
              info: "Updated Shipping Address",
              type: "event",
              timezone: "GMT",
            },
          ],
        },
        {
          name: "Process Checkout",
          data: [
            {
              x: 1672757934,
              y: 1672757935,
              info: "Process Checkout",
              type: "event",
              timezone: "GMT",
            },
          ],
        },
      ],
      performance: {
        battery: [
          {
            properties: {
              low_power: false,
              percentage: 0.43999999761581,
              state: "Unplugged",
            },
            timestamp: "2023-05-19T12:55:01.664+00:00",
          },
          {
            properties: {
              low_power: false,
              percentage: 0.44999998807907,
              state: "Unplugged",
            },
            timestamp: "2023-05-19T12:54:51.667+00:00",
          },
          {
            properties: {
              low_power: false,
              percentage: 0.46999999880791,
              state: "Unplugged",
            },
            timestamp: "2023-05-19T12:53:29.759+00:00",
          },
          {
            properties: {
              low_power: false,
              percentage: 0.47999998927116,
              state: "Unplugged",
            },
            timestamp: "2023-05-19T12:53:09.276+00:00",
          },
          {
            properties: {
              low_power: false,
              percentage: 0.49000000953674,
              state: "Unplugged",
            },
            timestamp: "2023-05-19T12:44:42.693+00:00",
          },
        ],
        connectivity: [
          {
            properties: {
              value: "wifi",
            },
            timestamp: "2023-05-19T12:23:59.497+00:00",
          },
          {
            properties: {
              value: "wifi",
            },
            timestamp: "2023-05-19T12:11:03.901+00:00",
          },
          {
            properties: {
              value: "4g",
            },
            timestamp: "2023-05-18T16:31:06.435+00:00",
          },
          {
            properties: {
              value: "wifi",
            },
            timestamp: "2023-05-16T09:52:58.575+00:00",
          },
          {
            properties: {
              value: "wifi",
            },
            timestamp: "2023-05-16T09:50:17.396+00:00",
          },
        ],
        orientation: [
          {
            properties: {
              device: "Portrait",
              interface: "Portrait",
            },
            timestamp: "2023-05-19T12:54:39.46+00:00",
          },
          {
            properties: {
              device: "FaceUp",
              interface: "Portrait",
            },
            timestamp: "2023-05-19T12:53:26.583+00:00",
          },
          {
            properties: {
              device: "Portrait",
              interface: "Portrait",
            },
            timestamp: "2023-05-19T12:53:13.089+00:00",
          },
          {
            properties: {
              device: "FaceUp",
              interface: "Portrait",
            },
            timestamp: "2023-05-19T12:52:42.742+00:00",
          },
          {
            properties: {
              device: "Portrait",
              interface: "Portrait",
            },
            timestamp: "2023-05-19T12:52:41.787+00:00",
          },
        ],
        resources: [
          {
            properties: {
              cpu: 1,
              ram: {
                total: 2107621376,
                used: 169722760,
              },
            },
            timestamp: "2023-05-19T12:55:39.328+00:00",
          },
          {
            properties: {
              cpu: 0.9,
              ram: {
                total: 2107621376,
                used: 169722760,
              },
            },
            timestamp: "2023-05-19T12:55:38.328+00:00",
          },
          {
            properties: {
              cpu: 1,
              ram: {
                total: 2107621376,
                used: 169722760,
              },
            },
            timestamp: "2023-05-19T12:55:37.328+00:00",
          },
          {
            properties: {
              cpu: 0.6,
              ram: {
                total: 2107621376,
                used: 169722760,
              },
            },
            timestamp: "2023-05-19T12:55:36.328+00:00",
          },
          {
            properties: {
              cpu: 1.4,
              ram: {
                total: 2107621376,
                used: 169722760,
              },
            },
            timestamp: "2023-05-19T12:55:35.327+00:00",
          },
        ],
        storage: [
          {
            properties: {
              free: 9615301061,
              total: 31978983424,
            },
            timestamp: "2023-05-19T12:55:25.394+00:00",
          },
          {
            properties: {
              free: 9615555013,
              total: 31978983424,
            },
            timestamp: "2023-05-19T12:55:05.359+00:00",
          },
          {
            properties: {
              free: 9613097413,
              total: 31978983424,
            },
            timestamp: "2023-05-19T12:54:47.504+00:00",
          },
          {
            properties: {
              free: 9622284741,
              total: 31978983424,
            },
            timestamp: "2023-05-19T12:54:25.399+00:00",
          },
          {
            properties: {
              free: 9622288837,
              total: 31978983424,
            },
            timestamp: "2023-05-19T12:54:05.36+00:00",
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters({
      getSessionDetails: "getSessionDetails",
    }),
    timeZone() {
      return "GMT";
    },
  },
  created() {
    this.getSessionPerformance();
  },
  beforeDestroy() {
    this.networkSeries = [];
    this.batterySeries = [];
    this.orientationSeries = [];
    this.interfaceOrientationSeries = [];
    this.eventsChartData = [];
    this.errorChartData = [];
    this.cpuChartData[0].data = [];
    this.ramChartData[0].data = [];
    this.storageChartData[0].data = [];
    this.batteryLineChartData[0].data = [];
    this.batteryLineChartOptions.annotations.points = [];
  },
  methods: {
    getSessionPerformance() {
      if (this.busy) return;
      this.busy = true;
      this.$store
        .dispatch(GET_SESSION_PERFORMANCE, {
          app_id: this.$route.params.app_id,
          session_id: this.$route.params.session_id,
          timestamp: "",
        })
        .then((response) => {
          if (response) {
            if (response.resources && response.resources.length > 0) {
              this.setLineChartDataForCpu(response.resources);
              this.setLineChartDataForRam(response.resources);
            }

            if (response.storage && response.storage.length > 0) {
              this.setLineChartDataForStorage(response.storage);
            }

            if (response.connectivity && response.connectivity.length > 0) {
              this.getNetworkUseData(response.connectivity);
            }

            if (response.battery && response.battery.length > 0) {
              this.getBatteryData(response.battery);
              this.setLineChartDataForBattary(response.battery);
            }

            if (response.orientation && response.orientation.length > 0) {
              this.getOrientationData(response.orientation);
            }
          }
          this.busy = false;
        })
        .catch((err) => {
          this.notifyErrorMessage(err.message);
          this.busy = false;
        });
    },
    setLineChartDataForRam(resources) {
      this.ramChartData[0].data = [];
      this.ramChartOptions.xaxis.categories = [];
      this.ramChartOptions.xaxis.min = this.getSessionDetails.startAt;
      this.ramChartOptions.xaxis.max = this.getSessionDetails.endAt;
      resources.forEach((data) => {
        let storage = formatBytes(data?.properties?.ram?.used / 1000);
        this.ramChartData[0]?.data?.push(parseInt(storage?.split(" ")[0]));
        this.ramChartOptions?.xaxis?.categories.push(data?.timestamp);
      });
    },
    setLineChartDataForCpu(resource) {
      this.cpuChartData[0].data = [];
      this.apexOptions.xaxis.categories = [];
      this.apexOptions.xaxis.min = this.getSessionDetails.startAt;
      this.apexOptions.xaxis.max = this.getSessionDetails.endAt;
      resource?.forEach((data) => {
        this.cpuChartData[0]?.data?.push(data?.properties?.cpu?.toFixed(2));
        this.apexOptions?.xaxis?.categories.push(data?.timestamp);
      });
    },
    setLineChartDataForStorage(resources) {
      this.storageChartData[0].data = [];
      this.storageChartOptions.xaxis.categories = [];
      this.storageChartOptions.xaxis.min = this.getSessionDetails.startAt;
      this.storageChartOptions.xaxis.max = this.getSessionDetails.endAt;
      resources.forEach((data) => {
        let storage = formatBytes(
          (data?.properties?.total - data?.properties?.free) / 1000
        );
        this.storageChartData[0]?.data?.push(parseInt(storage?.split(" ")[0]));
        this.storageChartOptions?.xaxis?.categories.push(data?.timestamp);
      });
    },
    setLineChartDataForBattary(battary) {
      this.batteryLineChartData[0].data = [];
      this.batteryLineChartOptions.xaxis.categories = [];
      this.batteryLineChartOptions.xaxis.min = this.getSessionDetails.startAt;
      this.batteryLineChartOptions.xaxis.max = this.getSessionDetails.endAt;
      battary?.forEach((data, index) => {
        const battaryPercentage = (data?.properties?.percentage * 100).toFixed(
          2
        );
        this.batteryLineChartData[0]?.data?.push(battaryPercentage);
        this.batteryLineChartOptions?.xaxis?.categories.push(data?.timestamp);

        if (
          index == 0 ||
          data?.properties?.state != battary[index - 1]?.properties?.state
        ) {
          const annotationData = {
            x: data?.timestamp,
            y: battaryPercentage,
            marker: {
              size: 8,
              fillColor: "#fff",
              strokeColor: "red",
              radius: 2,
              cssClass: "apexcharts-custom-class",
            },
            label: {
              borderColor: "#FF4560",
              offsetY: 0,
              style: {
                color: "#fff",
                background: "#FF4560",
              },
              text: data?.properties?.state,
            },
          };
          this.batteryLineChartOptions?.annotations?.points?.push(
            annotationData
          );
        }
      });
    },
    getNetworkUseData(connectivityList) {
      // try {
      this.networkSeries = [];
      const endTimestamp = dayjs(this.getSessionDetails.endAt).unix();
      const startTime = dayjs(this.getSessionDetails.startAt).unix();
      this.connectivityOptions.xaxis.startTime = startTime;
      this.connectivityOptions.xaxis.min = dayjs(
        this.getSessionDetails.startAt
      ).unix();
      this.connectivityOptions.xaxis.max = dayjs(
        this.getSessionDetails.endAt
      ).unix();
      connectivityList.forEach((data, index) => {
        const timestamp = dayjs(data?.timestamp).unix();
        const currentItemEndTimestamp = connectivityList[index + 1]
          ? dayjs(connectivityList[index + 1].timestamp).unix()
          : endTimestamp;

        this.networkSeries.push({
          data: [
            {
              x: "Network",
              info: this.networkInfos[data?.properties?.value],
              timezone: this.timeZone,
              y: [index === 0 ? startTime : timestamp, currentItemEndTimestamp],
            },
          ],
        });
      });
    },
    getOrientationData(orientationList) {
      // try {
      this.orientationSeries = [];
      this.interfaceOrientationSeries = [];
      const endTimestamp = dayjs(this.getSessionDetails.endAt).unix();
      const startTime = dayjs(this.getSessionDetails.startAt).unix();
      this.orientationOptions.xaxis.startTime = startTime;
      this.orientationOptions.xaxis.min = startTime;
      this.orientationOptions.xaxis.max = endTimestamp;

      this.interfaceOrientationOptions.xaxis.startTime = startTime;
      this.interfaceOrientationOptions.xaxis.min = startTime;
      this.interfaceOrientationOptions.xaxis.max = endTimestamp;

      orientationList.forEach((data, index) => {
        const timestamp = dayjs(data?.timestamp).unix();
        const currentItemEndTimestamp = orientationList[index + 1]
          ? dayjs(orientationList[index + 1].timestamp).unix()
          : endTimestamp;

        this.orientationSeries.push({
          data: [
            {
              x: "Orientation",
              info: this.orientationTypes[data?.properties?.device],
              timezone: this.timeZone,
              y: [index === 0 ? startTime : timestamp, currentItemEndTimestamp],
            },
          ],
        });

        this.interfaceOrientationSeries.push({
          data: [
            {
              x: "Orientation",
              info: this.orientationTypes[data?.properties?.interface],
              timezone: this.timeZone,
              y: [index === 0 ? startTime : timestamp, currentItemEndTimestamp],
            },
          ],
        });
      });
    },
    getBatteryData(batteryList) {
      // try {
      this.batterySeries = [];
      const endTimestamp = dayjs(this.getSessionDetails.endAt).unix();
      const startTime = dayjs(this.getSessionDetails.startAt).unix();
      this.batteryChartOptions.xaxis.startTime = startTime;
      this.batteryChartOptions.xaxis.min = dayjs(
        this.getSessionDetails.startAt
      ).unix();
      this.batteryChartOptions.xaxis.max = dayjs(
        this.getSessionDetails.endAt
      ).unix();

      batteryList?.forEach((data, index) => {
        let timestamp = dayjs(data?.timestamp).unix();
        let currentItemEndTimestamp = batteryList[index + 1]
          ? dayjs(batteryList[index + 1].timestamp).unix()
          : endTimestamp;

        this.batterySeries.push({
          name: data?.properties?.state,
          data: [
            {
              x: "Battery",
              info: data?.properties?.state,
              percentage: (data?.properties?.percentage * 100).toFixed(2),
              power_mode: data?.properties?.low_power,
              timezone: this.timeZone,
              y: [timestamp, currentItemEndTimestamp],
            },
          ],
        });
      });
    },
    setBubbleChartDataForEvents(eventsList) {
      this.eventsChartData = [];
      this.eventOptions.xaxis.min = dayjs(
        this.getSessionDetails.startAt
      ).unix();
      this.eventOptions.xaxis.max = dayjs(this.getSessionDetails.endAt).unix();
      eventsList.forEach((data) => {
        this.eventsChartData.push({
          name: data.type,
          data: [
            {
              x: data.timestamp,
              y: data.timestamp + 1,
              info: data.name,
              type: data.type,
              timezone: this.timeZone,
            },
          ],
        });
        this.eventOptions?.xaxis.categories.push(data.timestamp);
      });
    },
    setBubbleChartDataForErrors(errorsList) {
      this.errorChartData = [];
      this.errorOptions.xaxis.min = dayjs(
        this.getSessionDetails.startAt
      ).unix();
      this.errorOptions.xaxis.max = dayjs(this.getSessionDetails.endAt).unix();
      errorsList.forEach((data) => {
        this.errorChartData.push({
          name: data.type,
          data: [
            {
              x: data.timestamp,
              y: data.timestamp + 1,
              info: data.name,
              type: data.type,
              timezone: this.timeZone,
            },
          ],
        });

        this.errorOptions?.xaxis.categories.push(data.timestamp);
      });
    },
  },
};
</script>

<style scoped>
.watermark {
  opacity: 0.2;
  color: BLACK;
  position: absolute;
  bottom: 65%;
  -webkit-transform: skewY(50deg);
  transform: skew(-20deg, 30deg);
  font-size: 70px;
  right: 20%;
}

.secondwatermark {
  opacity: 0.2;
  color: BLACK;
  position: absolute;
  bottom: 20%;
  -webkit-transform: skewY(50deg);
  transform: skew(-20deg, 30deg);
  font-size: 70px;
  right: 20%;
}

.chart-wrapper {
  height: 50px !important;
  width: 600px;
}

.chart-row {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}

.chart-label {
  width: 250px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.compact {
  margin-top: -100px;
}
</style>